/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import tw, { css, theme } from "twin.macro";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";

const DonatePage = ({ data, location }) => {
  // ===========================================================================
  // variables

  const cms = data.sanityDonatePage;

  // ===========================================================================
  // render

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout _css={[tw`min-h-[90vh] relative`]}>
        {/* // background grid haxx // */}
        <div
          css={[
            css`
              width: 50vw;
              background-color: ${cms.backgroundColor?.hex || `white`};
            `,
            tw`h-full absolute top-0 left-0 bottom-0 hidden md:block`
          ]}
        />
        <div
          css={[
            css`
              width: 50vw;
            `,
            tw`h-full absolute top-0 right-0 bottom-0 hidden md:block bg-green-medium`
          ]}
        />

        <Grid>
          <div
            css={[
              css`
                background-color: ${cms.backgroundColor?.hex || `white`};
                color: ${cms.textColor?.hex || `white`};
              `,
              tw`col-span-full md:col-span-10 md:col-start-2 relative pt-10 md:pt-20 pb-10`
            ]}
          >
            {/* // background grid haxx // */}
            <div
              css={[
                css`
                  background-color: ${cms.backgroundColor?.hex || `white`};
                `,
                tw`w-[calc(100% + 2rem)] h-full absolute top-0 left-[-1rem] bottom-0 md:hidden`
              ]}
            />

            <article css={[tw`relative z-10`]}>
              <T.Head font="1" level="1">
                {cms.header}
              </T.Head>

              <T.Body _css={[tw`mt-8`]} font="h4">
                {cms.subheader}
              </T.Body>
            </article>
          </div>

          <div
            css={[
              tw`col-span-full md:col-span-12 md:col-start-13 md:h-[860px] relative flex items-center justify-center bg-green-medium`
            ]}
          >
            {/* // background grid haxx // */}
            <div
              css={[
                tw`w-[calc(100% + 2rem)] h-full absolute top-0 left-[-1rem] bottom-0 md:hidden bg-green-medium`
              ]}
            />

            <iframe
              css={[
                tw`w-full h-[940px] md:h-[650px] relative block pt-24 md:pt-0 pb-24 md:pb-0`
              ]}
              title="raisely"
              src="https://eatup.raisely.com/embed/"
            />
          </div>
        </Grid>
      </Layout>

      <Footer />
    </>
  );
};

export default DonatePage;

export const query = graphql`
  query DonatePage {
    sanityDonatePage {
      title
      textColor {
        hex
      }
      backgroundColor {
        hex
      }
      header
      subheader
      seoDescription
      seoKeywords
    }
  }
`;
